import actions from './actions';
import getters from './getters';
import mutations from './mutations';

export default {
  namespaced: true,
  state: {
    verify_token:[],
    session:'',
    org_node_branding:[],
    lang_filter: [],
    status_msg:'',
    answer_msg:'',
    page:'',
    log_page_key:0,
    ip_location:''
  },
  actions,
  getters,
  mutations,
};
