//----------------------------------------------
// SKARPA
//----------------------------------------------
// export const QUERY_URL_REST2 = 'https://infoping.net/REST2/ask/Q/json/?function=';
// export const QUERY_URL = 'https://infoping.net/REST2/ask/Q/json/';

//----------------------------------------------
// DEV
//----------------------------------------------
// export const QUERY_URL_REST2 = 'https://dev.infoping.se/REST2/ask/Q/json/?function=';
// export const QUERY_URL = 'https://dev.infoping.se/REST2/ask/Q/json/';

//----------------------------------------------
// ALLA
//----------------------------------------------
export const COOKIE_NAME_SESSION = 'infopingSession'; 