// import {
//   QUERY_URL,
// } from '@/constants.js';
//----------------------------------------------
// URL
//----------------------------------------------

const URL_IP_FUNCTION =
  process.env.NODE_ENV === "production"
    ? "https://infoping.net/REST2/ask/Q/json/?function="
    : "http://infoping.se/REST2/ask/Q/json/?function=";

const URL_IP =
  process.env.NODE_ENV === "production"
    ? "https://infoping.net/REST2/ask/Q/"
    : "http://infoping.se/REST2/ask/Q/";

//----------------------------------------------
// IMPORT
//----------------------------------------------
import axios from "axios";
import get from "lodash/get";

export default {
  //----------------------------------------------
  // GENERELL
  //----------------------------------------------
  async ip_address() {
      return await axios
        .get('https://api.ipify.org?format=json', {
          headers: { "Content-Type": "application/json" },
        })
        .then((res) => {
          var ans = res.data;

          return ans;
        })
        .catch((e) => {
          
          return null;
        });
  },
  async ip_location(ip_number) {
    return await axios
      .get('https://ipapi.co/'+ip_number+'/json/', {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        var ans = res.data;

        return ans;
      })
      .catch((e) => {
        
        return null;
      });
},
  //----------------------------------------------
  // INFOPING
  //----------------------------------------------
  async leads_msg(obj) {
    const funktion = "leads_msg";
    var post_data = JSON.stringify(obj);
    const url = URL_IP + funktion;
    //console.log(funktion, url, post_data);
    return await axios
      .post(url, post_data, {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((res) => {
        return get(res, "data.A", []);
      })
      .catch((e) => {
        return { status: 0, error: e.message };
      });
  },
  async msg_create_v4(obj) {
    const funktion = "msg_create_v4";
    var post_data = JSON.stringify(obj);
    const url = URL_IP_FUNCTION + funktion;
    //console.log(funktion, url, post_data);
    return await axios
      .post(url, post_data, {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((res) => {
        return get(res, "data.A", []);
      })
      .catch((e) => {
        return { status: 0, error: e.message };
      });
  },
  async lang_filter(obj) {
    const funktion = "lang_filter";
    var lang = "sv";
    var filter = "all";

    if (obj.lang) {
      if (obj.lang) {
        if (obj.lang.trim() != "") lang = obj.lang;
      }
      if (obj.filter) {
        if (obj.filter.trim() != "") filter = obj.filter;
      }
      const url = URL_IP + funktion + "/" + lang + "/" + filter;

      //console.log(funktion,'url', url);
      return await axios
        .get(url, {
          headers: { "Content-Type": "application/json" },
        })
        .then((res) => {
          var ans = res.data;

          //console.log(funktion,'ans', ans);
          return ans;
        })
        .catch((e) => {
          alert("ERROR:" + e);
          return null;
        });
    } else return null;
  },
  async lang_chk(lang) {
    
    const funktion='lang_chk';
    
    const url =
    URL_IP +
    funktion +'/'+lang;
      
    // console.log(funktion,'url', url);
    return await axios
      .get(url, {
        headers: { 'Content-Type': 'application/json' },
      })
      .then((res) => {
        var ans = res.data;
      //  console.log('ans lang', ans);
        return ans;
      })
      .catch((e) => {
        alert('ERROR:' + e);
        return null;
      });
    
    
  },
  async status_msg(obj) {
    const funktion = "status/msg";
    var phone_number_only_digit=obj.Q.phone_number.replace(/\D/g, "");

    
      const url = URL_IP + funktion + "/" + obj.Q.message_key + "/" + phone_number_only_digit;

      //console.log(funktion,'url', url);
      return await axios
        .get(url, {
          headers: { "Content-Type": "application/json" },
        })
        .then((res) => {
          var ans = res.data;

          //console.log(funktion,'ans', ans);
          return ans;
        })
        .catch((e) => {
          alert("ERROR:" + e);
          return null;
        });
  },
  async answer_msg(obj) {
    const funktion = "answer/msg";
    var phone_number_only_digit=obj.Q.phone_number.replace(/\D/g, "");

    
      const url = URL_IP + funktion + "/" + obj.Q.message_key + "/" + phone_number_only_digit+'/'+obj.Q.lang;

      //console.log(funktion,'url', url);
      return await axios
        .get(url, {
          headers: { "Content-Type": "application/json" },
        })
        .then((res) => {
          var ans = res.data;

          //console.log(funktion,'ans', ans);
          return ans;
        })
        .catch((e) => {
          alert("ERROR:" + e);
          return null;
        });
  },
  async verify_token(obj) {
    const funktion = "verify_token_v3";
    var post_data = JSON.stringify(obj);
    const url = URL_IP_FUNCTION + funktion;
    return await axios
      .post(url, post_data, {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((res) => {
        return get(res, "data.A", []);
      })
      .catch((e) => {
        return { status: 0, error: e.message };
      });
  },
  async org_node_branding(obj) {
    const funktion = "org_node_branding";
    var post_data = JSON.stringify(obj);
    const url = URL_IP_FUNCTION + funktion;
    //console.log(funktion, url, post_data);
    return await axios
      .post(url, post_data, {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((res) => {
        //console.log('org_node_branding', res);
        return get(res, "data.A", []);
      })
      .catch((e) => {
        return { status: 0, error: e.message };
      });
  },

  async initiateLogin(phone_number) {
    const funktion = "login_initiate";
    const post_data = '{ "Q": { "phone_number": "' + phone_number + '"}}';
    const url = URL_IP_FUNCTION + funktion;
    //console.log('post_data', post_data);
    return await axios
      .post(url, post_data, {
        // useCredentails: true,
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((res) => {
        //console.log('svaret init login', res);
        return res.data.A;
      })
      .catch((e) => {
        //console.log("ERROR", e);
        return { status: "0", error: e.message };
      });
  },

  async getCode(phone_number) {
    const funktion = "login_sms_send";
    const post_data = '{ "Q": { "phone_number": "' + phone_number + '"}}';
    const url = URL_IP_FUNCTION + funktion;

    return await axios
      .post(url, post_data, {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((res) => {
        return res.data.A;
      })
      .catch((e) => {
        //console.log("ERROR", e);
        return { status: "0", error: e.message };
      });
  },
  async confirmCode(sms_code, phone_number) {
    const funktion = "login_sms_confirm";
    const post_data = `{
    "Q": {
      "phone_number": "${phone_number}",
      "code": "${sms_code}",
    }`;
    const url = URL_IP_FUNCTION + funktion;

    return await axios
      .post(url, post_data, {
        // useCredentails!: true,
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((res) => {
        //console.log('svaretfrånservernefter kod', res);
        return res.data.A;
      })
      .catch((e) => {
        //console.log("ERROR", e);
        return { status: "0", error: e.message };
      });
  },
  async log_page(post_data) {
    const funktion='log_page';
    const url =
    URL_IP + funktion+'/'
    //console.log(funktion, url, post_data);
    return await axios
      .post(url, post_data, {
        // useCredentails: true,
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
      })
      .then((res) => {
         //console.log(funktion,'ans', res.data);
        return res.data;
      })
      .catch((e) => {
        //console.log('ERROR', e);
        return { status: 0, error: e.message };
      });
  },
  async log_page_event(post_data) {
    const funktion='log_page_event';
    const url =
    URL_IP + funktion+'/'
    //console.log(funktion, url, post_data);
    return await axios
      .post(url, post_data, {
        // useCredentails: true,
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((e) => {
        //console.log('ERROR', e);
        return { status: 0, error: e.message };
      });
  },
 
};
