import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'

Vue.use(Vuetify)

// const opts = {}
const opts =
{
    theme: {
      options: {
        customProperties: false,
      },
    },
  };

export default new Vuetify(opts)
