import {
  VERIFY_TOKEN,
  SESSION,
  ORG_NODE_BRANDING,
  LANG_FILTER,
  STATUS_MSG,
  ANSWER_MSG,
  PAGE,
  LOG_PAGE_KEY,
  IP_LOCATION,
} from './types.js';

export default {
  //----------------------------------------------
  // INFOPING
  //----------------------------------------------
  
  [VERIFY_TOKEN](state, payload) {
    state.verify_token = payload;
  },
  [SESSION](state, payload) {
    state.session = payload;
  },
  [ORG_NODE_BRANDING](state, payload) {
    state.org_node_branding = payload;
  },
  [LANG_FILTER](state, payload) {
    state.lang_filter = payload;
  },
  [STATUS_MSG](state, payload) {
    state.status_msg = payload;
  },
  [ANSWER_MSG](state, payload) {
    state.answer_msg = payload;
  },
  [PAGE](state, payload) {
    state.page = payload;
  },
  [LOG_PAGE_KEY](state, payload) {
    state.log_page_key = payload;
  },
  [IP_LOCATION](state, payload) {
    state.ip_location = payload;
  },

  
};
