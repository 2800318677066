<template>
  <v-app>
    <div class="loginBackground">
      <!-- <div v-if="!isMobile()"> -->
      <div>
        <v-dialog persistent v-model="isDialogOpen" width="400">
          <v-card>
            <v-card-title class="headline" primary-title>{{
              getLang('v5_login', 'lbl_title_verify')
            }}</v-card-title>

            <v-card-text>
              <v-form v-model="form1Valid">
                <div style="padding-top:40px">
                  <v-text-field
                    filled
                    v-model="phone_number"
                    :label="getLang('v5_login', 'lbl_phone')"
                    clearable
                    :disabled="showconfirm || loading"
                    @keydown="keypressedPhone"
                    autofocus
                  ></v-text-field>
                </div>
              </v-form>

              <!--         
  från telnrfältets
            @keydown="keypressedPhone"              
              :rules='[rules.required, rules.phone_number]'-->

              <div v-if="showconfirm">
                <label>{{ getLang('v5_login', 'lbl_entercode') }}:</label>
                <br />
                <v-text-field
                  class="upperCaseInput"
                  filled
                  v-model="sms_code"
                  :label="getLang('v5_login', 'lbl_code')"
                  placeholder="XX-1234"
                  :rules="[rules.required, rules.otp]"
                  clearable
                  autofocus
                  :disabled="loading"
                  @keydown="keypressedCode"
                ></v-text-field>
              </div>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-btn
                v-if="showconfirm"
                color="secondary"
                text
                @click="resetForm"
                >{{ getLang('v5_button', 'lbl_cancel') }}</v-btn
              >

              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                text
                :loading="loading"
                @click="confirmButtonClick"
                :disabled="!form1Valid || loading"
                >{{ confirmButtonLabel }}</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </div>
  </v-app>
</template>

<script>
import rest2Api from '@/api/rest2Api.js';
import { COOKIE_NAME_SESSION } from '@/constants.js';
import pageMixin from "@/pages/pageMixin.js";
import get from "lodash/get";

export default {
  name: 'login-page',
  bodyClass: 'login-page',
  mixins: [pageMixin],
  data() {
    return {
      phone_number: '',
      sms_code: '',
      showconfirm: false,
      isDialogOpen: false,
      cbLabel: 1,
      form1Valid: true,
      loading: false,
      rules: {
        required: (value) => !!value || this.getLang('v5_rule', 'lbl_required'),
        phone_number: (value) => {
          const pattern = /^[+]*[0-9]{2,2}[1-9]{1,1}[0-9]{8,8}$/;
          return (
            pattern.test(value) || this.getLang('v5_rule', 'lbl_invalidnbr')
          );
        },
        otp: (value) => {
          const pattern = /^[A-Za-z]{2,2}[-][0-9]{4,4}$/;
          return (
            pattern.test(value) || this.getLang('v5_rule', 'lbl_invalidcode')
          );
        },
      },
      lang: 'en',
    };
  },
  computed: {
    confirmButtonLabel() {
      if (this.cbLabel == 1) return this.getLang('v5_button', 'lbl_next');
      else return this.getLang('v5_login', 'lbl_verifycode');
    },
  },
  created() {
    //loginrutan sv om browser language är sv annars en
    // var browserLang = (
    //   (navigator.languages && navigator.languages[0]) ||
    //   ''
    // ).substr(0, 2);

    // if (browserLang && browserLang == 'sv') {
    //   this.lang = 'sv';
    // } else this.lang = 'en';

    // //lite kaka på kaka eftersom jag sätter en om det inte är sv, men om i implementerar fler språk är det bra att checka att språket finns
    // this.lang_chk(this.lang).then(() => {
    //   this.$store.dispatch('infopingstore/fetch_lang_filter', {
    //     lang: this.lang,
    //     filter: 'all',
    //   });
    // });
  },

  mounted() {
    window.setTimeout(() => (this.isDialogOpen = true), 1000);
    this.page_event_save("login",'page');
    //this.confirmButtonLabel = this.getLang('v5_button', 'lbl_next');
  },
  watch: {
    sms_code() {
      if (this.sms_code && this.sms_code.length == 2) this.sms_code += '-';
    },
  },
  methods: {
    
    initLogin() {
      if (this.form1Valid) {
        console.log('init login!: ', this.phone_number);
        rest2Api.initiateLogin(this.phone_number).then((res) => {
          if (res.status == '1') this.getCode();
          else
            this.$store.dispatch('layout/showAlert', {
              show: true,
              bodyText: res.error,
            });
        });
      }
    },

    getCode() {
      console.log('get code!: ', this.phone_number);
      this.cbLabel = 2;
      //this.confirmButtonLabel();
      // this.confirmButtonLabel =  this.getLang('v5_login', 'lbl_verifycode');

      rest2Api.getCode(this.phone_number);
      this.showconfirm = true;
    },
    confirmCode() {
      //console.log("koden är: ", this.sms_code);
      //skicka till api och vänta på svar .then
      rest2Api.confirmCode(this.sms_code, this.phone_number).then((res) => {
        if (res.status == '1') {
          this.page_event_save("login",'successful');
          let lang=get(this.$store.state.infopingstore,'page.lang','en');
          let tag=get(this.$store.state.infopingstore,'page.tag','');
          let page=get(this.$store.state.infopingstore,'page.page','');
          let key=get(this.$store.state.infopingstore,'log_page_key','');
          var path = '/?lang='+lang+'&tag='+tag+'&page='+page+'&key='+key;

          //om cookies inte tillåts lägg med sessionen i browsern
          if (navigator.cookieEnabled != true) {
            window.location.href = path + '&s=' + res.session;
          } else {
            //  sätt sessionen i cookien ist för att skriva den i browsern
            this.$cookies.set(COOKIE_NAME_SESSION, res.session);
            // var path =  "/";
            window.location.href = path; //+ "?s=" + res.session;
          }
        } else {
          var errorMsg = res.error;

          if (errorMsg == 'login not initiated') {
            errorMsg = this.getLang('v5_error', 'lbl_wrongcode');

            this.$store
              .dispatch('layout/showAlert', {
                show: true,
                bodyText: errorMsg, //res.error,
              })
              .then(() => {
                //  this.resetForm();
                this.sms_code = '';
                this.loading = false;
              });
          }

          if (errorMsg == 'login timed out') {
            errorMsg = this.getLang('v5_error', 'lbl_logintimeout');

            this.$store
              .dispatch('layout/showAlert', {
                show: true,
                bodyText: errorMsg, //res.error,
              })
              .then(() => {
                this.resetForm();
              });
          }
          // popup slutar funka
          //this.resetForm();
        }
      });
    },
    keypressedPhone(keyEvt) {
      //internet explorer (edge) buggade när +46 lades till automatiskt
      //  if (keyEvt.key == "0" && this.phone_number == "") {

      // this.phone_number = "+46";
      // keyEvt.preventDefault();

      //} else if
      if (keyEvt.key == 'Enter') this.confirmButtonClick();
    },
    keypressedCode(keyEvt) {
      if (keyEvt.key == 'Enter') this.confirmButtonClick();
      if (keyEvt.key == '-') {
        keyEvt.preventDefault();
      }
      // if (this.sms_code && this.sms_code.length>7)
      //   keyEvt.preventDefault();
    },
    onDialogClosed() {
      this.isDialogOpen = true;
    },
    confirmButtonClick() {
      if (this.showconfirm == false) {
        this.showconfirm = true;
        this.isDialogOpen = true;
        this.initLogin();
      } else {
        if (this.sms_code != '') {
          this.loading = true;
          this.confirmCode();
        }
      }
    },
    resetForm() {
      this.isDialogOpen = true;
      this.showconfirm = false;
      this.phone_number = '';
      this.sms_code = '';
      this.loading = false;
      this.cbLabel = 1;
      // this.confirmButtonLabel();
      // this.confirmButtonLabel = this.getLang('v5_button', 'lbl_next');
    },

    // isMobile() {
    //   if (
    //     /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    //       navigator.userAgent
    //     )
    //   ) {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // },
  },
  components: {},
};
</script>

<style>
body,
html {
  height: 100%;
  width: 100%;
}

.upperCaseInput input {
  text-transform: uppercase;
}

.loginBackground {
  /* background-image: url('~@/assets/watermark-wide.png'); */
  background-image: url('~@/assets/login_background.jpg');
  height: 100%;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
}

.headlineback {
  background-color: #47cb87;
  color: white;
}
</style>
