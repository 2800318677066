<template>
  <v-app>
    <div id="app">
      <Stenmark v-if="is_stenmark" />
      <Default v-if="is_default" :lang="lang" :tag="tag"/>
    </div>
  </v-app>
</template>

<script>
import Stenmark from "./pages/Stenmark.vue";
import Default from "./pages/Default.vue";
import pageMixin from "./pages/pageMixin.js";
import rest2Api from "@/api/rest2Api.js";
import get from "lodash/get";

// mobile-device-detect
import { deviceDetect } from "mobile-device-detect";
import { isMobile } from "mobile-device-detect";
import { isMobileOnly } from "mobile-device-detect";
import { isTablet } from "mobile-device-detect";
import { isBrowser } from "mobile-device-detect";
import { isSmartTV } from "mobile-device-detect";
import { isWearable } from "mobile-device-detect";
import { isConsole } from "mobile-device-detect";
import { isAndroid } from "mobile-device-detect";
import { isWinPhone } from "mobile-device-detect";
import { isIOS } from "mobile-device-detect";
import { isChrome } from "mobile-device-detect";
import { isFirefox } from "mobile-device-detect";
import { isSafari } from "mobile-device-detect";
import { isOpera } from "mobile-device-detect";
import { isIE } from "mobile-device-detect";
import { isEdge } from "mobile-device-detect";
import { isYandex } from "mobile-device-detect";
import { isChromium } from "mobile-device-detect";
import { isMobileSafari } from "mobile-device-detect";
import { isIOS13 } from "mobile-device-detect";
import { isIPhone13 } from "mobile-device-detect";
import { isIPad13 } from "mobile-device-detect";
import { isIPod13 } from "mobile-device-detect";
import { isElectron } from "mobile-device-detect";
import { isEdgeChromium } from "mobile-device-detect";
import { isLegacyEdge } from "mobile-device-detect";
import { isWindows } from "mobile-device-detect";
import { isMacOs } from "mobile-device-detect";
import { osVersion } from "mobile-device-detect";
import { osName } from "mobile-device-detect";
import { fullBrowserVersion } from "mobile-device-detect";
import { browserVersion } from "mobile-device-detect";
import { browserName } from "mobile-device-detect";
import { mobileVendor } from "mobile-device-detect";
import { mobileModel } from "mobile-device-detect";
import { engineName } from "mobile-device-detect";
import { engineVersion } from "mobile-device-detect";
import { getUA } from "mobile-device-detect";
import { deviceType } from "mobile-device-detect";

export default {
  name: "App",
  mixins: [pageMixin],
  components: {
    Stenmark,
    Default,
  },
  data() {
    return {
      is_stenmark: false,
      is_default: false,
      is_test: false,
      dummy: null,
      lang:'',
      tag:''
    };
  },
  methods: {
    setPage(page) {
      if (page == "Stenmark") {
        this.is_stenmark = true;
      } else if (page == "Test") {
        this.is_test = true;
      } else this.is_default = true;

      //console.log("page", page, this.is_stenmark, this.is_default);
    },
   
  },
  beforeDestroy(){

  },
  created(){
 
  },
 

  beforeMount() {
    //dirigera om användaren till https om de kommer via http  (endast prod)
    var loc = window.location + "";
    if (loc.indexOf("http://") == 0 && process.env.NODE_ENV === "production") {
      window.location.href = loc.replace("http://", "https://");
      return;
    }

    // URL
    const urlparams = new URLSearchParams(window.location.search);
    var lang = this.null_if_empty(urlparams.get("lang"));
    var page = this.empty_if_null(urlparams.get("page"));
    var tag = this.empty_if_null(urlparams.get("tag"));
    var key = this.empty_if_null(urlparams.get("key"));
    var ip_number = "";
    this.tag=tag;

    if (lang == null)
    {
      var browserLang = (
      (navigator.languages && navigator.languages[0]) ||
      ''
    ).substr(0, 2);

    if (browserLang) {
      lang = browserLang;
    } 
    } 
    if(key=='')
    {
    var device = deviceDetect();
    device.isMobile = isMobile;
    device.isMobileOnly = isMobileOnly;
    device.isTablet = isTablet;
    device.isBrowser = isBrowser;
    device.isSmartTV = isSmartTV;
    device.isWearable = isWearable;
    device.isConsole = isConsole;
    device.isAndroid = isAndroid;
    device.isWinPhone = isWinPhone;
    device.isIOS = isIOS;
    device.isChrome = isChrome;
    device.isFirefox = isFirefox;
    device.isSafari = isSafari;
    device.isOpera = isOpera;
    device.isIE = isIE;
    device.isEdge = isEdge;
    device.isYandex = isYandex;
    device.isChromium = isChromium;
    device.isMobileSafari = isMobileSafari;
    device.isIOS13 = isIOS13;
    device.isIPhone13 = isIPhone13;
    device.isIPad13 = isIPad13;
    device.isIPod13 = isIPod13;
    device.isElectron = isElectron;
    device.isEdgeChromium = isEdgeChromium;
    device.isLegacyEdge = isLegacyEdge;
    device.isWindows = isWindows;
    device.isMacOs = isMacOs;
    device.osVersion = osVersion;
    device.osName = osName;
    device.fullBrowserVersion = fullBrowserVersion;
    device.browserVersion = browserVersion;
    device.browserName = browserName;
    device.mobileVendor = mobileVendor;
    device.mobileModel = mobileModel;
    device.engineName = engineName;
    device.engineVersion = engineVersion;
    device.getUA = getUA;
    device.deviceType = deviceType;

    // GET LANG
    this.lang_chk(lang).then(() => {
    this.$store
      .dispatch("infopingstore/fetch_lang_filter", {
        lang: lang,
        filter: "all",
      })
      // GET IP
      .then((res) => {
        this.lang=lang;
        this.dummy = res;
        rest2Api.ip_address().then((res) => {
          ip_number = this.empty_if_null(res.ip);
          // SAVE PAGE AND USER IN STORE
          var obj = new Object();
          obj.device = device;
          obj.ip_number = ip_number;
          obj.page = page;
          obj.lang = lang;
          obj.tag = tag;
          this.$store.dispatch("infopingstore/fetch_page", obj).then((res) => {
            rest2Api.log_page(res).then((res) => {
              this.$store
                .dispatch("infopingstore/fetch_log_page_key", res.log_page_key)
                .then((res) => {
                  this.page_event_save("start");
                  this.$store
                .dispatch("infopingstore/fetch_ip_location",ip_number).then((res=>{

                      let country_name=get(res,"country_name",'');
                      let city=get(res,"city",'');
                      let org=get(res,"org",'');
                      let latitude=get(res,"latitude",'');
                      let longitude=get(res,"longitude",'');

                      if(country_name!='')
                        this.page_event_save("country_name",country_name);
                        
                      if(city!='')
                        this.page_event_save("city",city);
                      if(org!='')
                        this.page_event_save("org",org);
                      if(latitude!='')
                        this.page_event_save("latitude",latitude);
                      if(longitude!='')
                        this.page_event_save("longitude",longitude);

                    this.setPage(page);
                  }))

                 
                });
            });
          });
        });
      });
  })}
   else
  {
    this.$store.dispatch("infopingstore/fetch_log_page_key", key).then((res=>{
      this.dummy=res;
      this.lang_chk(lang).then((res=>{
        this.dummy=res;
        this.$store
      .dispatch("infopingstore/fetch_lang_filter", {
        lang: lang,
        filter: "all",
      }).then((res=>{
        this.dummy=res;
        this.setPage(page);
      }))
      }))
    }))
  }
  }
 ,
};
</script>

<style lang="scss">
#app {
  font-family: "semplicita_pro";
}
</style>
