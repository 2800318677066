import { 
  VERIFY_TOKEN, 
  SESSION,
  ORG_NODE_BRANDING, 
  LANG_FILTER,
  STATUS_MSG,
  ANSWER_MSG,
  PAGE,
  LOG_PAGE_KEY,
  IP_LOCATION,
} from "./types.js";

import rest2Api from "@/api/rest2Api.js";
export default {
  //----------------------------------------------
  // GENERELL
  //----------------------------------------------
  async fetch_ip_location({ commit }, ip_number) {
    var response = await rest2Api.ip_location(ip_number);

    commit(IP_LOCATION, response);
    

    return response;
  },
  //----------------------------------------------
  // INFOPING
  //----------------------------------------------
  async fetch_verify_token({ commit }, obj) {
    var response = await rest2Api.verify_token(obj);

    if (response.status == 1) commit(VERIFY_TOKEN, response);
    else commit(VERIFY_TOKEN, []);

    return response;
  },
  async fetch_session({ commit }, session) {
    if (session.length >= 36) commit(SESSION, session);
    else commit(SESSION, "");

    return session;
  },
  async fetch_org_node_branding({ commit }, obj) {
    var response = await rest2Api.org_node_branding(obj);

    if (response.status == 1) commit(ORG_NODE_BRANDING, response);
    else commit(ORG_NODE_BRANDING, []);

    return response;
  },
  
    async fetch_lang_filter({ commit }, obj) {

      var ans = await rest2Api.lang_filter(obj);
        commit(LANG_FILTER, ans);
        return ans;
    },
  
    async fetch_status_msg({ commit }, obj) {

      var ans = await rest2Api.status_msg(obj);
        commit(STATUS_MSG, ans);
        return ans;
    }, 
    async fetch_answer_msg({ commit }, obj) {

      var ans = await rest2Api.answer_msg(obj);
        commit(ANSWER_MSG, ans);
        return ans;
    }, 
    async fetch_page({ commit }, obj) {
      commit(PAGE, obj);
  
      return obj;
    },
    async fetch_log_page_key({ commit }, obj) {
      commit(LOG_PAGE_KEY, obj);
  
      return obj;
    },
    
    
};
