export default {

//----------------------------------------------
//  UTIL
//----------------------------------------------
clean_null_obj(obj){
  return JSON.parse(JSON.stringify(obj,(key, value) => {
    if (value !== null) return value
  }))
},
//----------------------------------------------
// INFOPING
//----------------------------------------------
ip_answer_msg_obj() {
  const obj = new Object();
  const Q = new Object();
  Q.message_key = null;
  Q.phone_number = null;
  Q.lang = null;
  obj.Q = Q;
  return obj;
},
ip_status_msg_obj() {
  const obj = new Object();
  const Q = new Object();
  Q.message_key = null;
  Q.phone_number = null;
  obj.Q = Q;
  return obj;
},
ip_verify_token_obj() {
  const obj = new Object();
  const Q = new Object();
  Q.session = null;
  Q.token = null;
  Q.login = null;
  obj.Q = Q;
  return obj;
},
ip_org_node_branding_obj() {
  const obj = new Object();
  const Q = new Object();
  Q.org_key = null;
  Q.node_id = null;
  obj.Q = Q;
  return obj;
},
ip_leads_msg_obj(){
  const obj = new Object();
  const Q = new Object();
  Q.org_key = null;
  Q.node_id = null;
  Q.body = null;
  
  obj.Q = Q;
  return obj;
},
ip_msg_create_v4_obj() {
  const obj = new Object();
  const Q = new Object();
  Q.session = null;
  Q.org_key = null;
  Q.multiple_question_key=null;
  Q.date_time=null;
  Q.body=null;
  Q.response_id=null;


  const msg_setting = new Object();
  msg_setting.days=null;
  msg_setting.time=null;
  msg_setting.reminder_after_sent=null;
  msg_setting.reminder_before_expire=null;
  msg_setting.permission_detail=null;
  msg_setting.message_delivery_type_key=null;
  msg_setting.push_response=null;
  msg_setting.first_hidden=null;
  msg_setting.first_count=null;
  msg_setting.allow_comment=null;

  Q.msg_setting=msg_setting;

  const node_ids= new Array();

  Q.node_ids=node_ids;

  const questions = new Array();
  const question= new Object();
  question.response_id=null;
  question.first_hidden=null;
  question.first_count=null;
  question.allow_comment=null;
  question.body=null;
  question.header_text=null;

  const attachments_v3 = new Array();
  const attachment = new Object();
  attachment.file_name=null;
  attachment.base64=null;
  attachments_v3.push(attachment);

  question.attachments_v3=attachments_v3;

  questions.push(question);

   Q.questions=questions;

  const receivers = new Array();
  const receiver = new Object();
  receiver.phone_number=null;
  receiver.user_name=null;
  receivers.push(receiver);

  Q.receivers=receivers;

  Q.attachments_v3=attachments_v3;
  



  obj.Q = Q;
  return obj;
},
};
