

//----------------------------------------------
// INFOPING
//----------------------------------------------
export const VERIFY_TOKEN = 'VERIFY_TOKEN';
export const SESSION = 'SESSION';
export const ORG_NODE_BRANDING = 'ORG_NODE_BRANDING';
export const LANG_FILTER = 'LANG_FILTER';
export const STATUS_MSG = 'STATUS_MSG';
export const ANSWER_MSG = 'ANSWER_MSG';
export const PAGE = 'PAGE';
export const LOG_PAGE_KEY = 'LOG_PAGE_KEY';
export const IP_LOCATION = 'IP_LOCATION';


