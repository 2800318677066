<template>
  <div>
     <vue-headful :title="title" />
    <Header :header="getLang('page', 'header_send_test')" />

    <div v-if="loggedin">
      <div v-if="show_send">
        <v-container>
          <v-subheader>{{getLang('page', 'send_to_other')}}
          </v-subheader>
          <br />
          <v-text-field
            v-model="phone_number"
            :label="getLabel()"
            outlined
            clearable
          >
          </v-text-field>
          <v-textarea
            :label="getLang('page', 'message')"
            auto-grow
            outlined
            rows="2"
            row-height="15"
            v-model="msg"
          >
          </v-textarea>
          <v-btn
            class="btn-send"
            dark
            @click="send_msg"
            elevation="0"
            :color="this.get_backgroundcolor(true)"
          >
            {{ this.getLang("new_message__view", "btn_send") }}
          </v-btn>
        </v-container>
      </div>
      <v-container>
          <v-col cols="12" md="12">
            <!-- HEADER -->
            <v-row align="center" justify="center" class="row-status">
              <v-col cols="4" md=4>
              <div v-if="show_refresh">
               <v-btn
               v-if="this.poll_intervall >0"
                  :color="this.get_backgroundcolor(true)"
                  dark
                  x-small
                  elevation="0"
                  loading
                  fab

                >
                 
                </v-btn>
                <v-btn
                  v-else
                   icon
                   small
                   :color="this.get_backgroundcolor(true)"
                   @click="click_start_refresh()"
            >
              <v-icon large>mdi-refresh</v-icon>
            </v-btn>
              </div>
              </v-col>

              <v-col cols="4">
                {{ this.getLang("v4_stat", "lbl_status") }}
              </v-col>

              <v-col cols="4">
               {{ this.getLang("page", "lbl_when") }}
              </v-col>

              
            </v-row>
            <!-- SENT -->
            <v-row align="center" justify="center">
              <v-col cols="4" class=" d-flex">
                <v-btn
                  :color="this.get_backgroundcolor(this.sent)"
                  dark
                  x-small
                  fab
                  elevation="0"
                >
                  <span class="numberText">1</span>
                </v-btn>
                <span class="verticalLine" />
              </v-col>

              <v-col cols="4">
                {{ this.getLang("v4_status", "SENT") }}
              </v-col>

              <v-col cols="4">
                {{ this.sent_time }}
              </v-col>
            </v-row>
            <!-- REJECTED -->
            <v-row v-if="rejected" align="center" justify="center">
              <v-col cols="4" class="d-flex">
                <v-btn color="red" dark x-small fab elevation="0">
                  <span class="numberText">X</span>
                </v-btn>
                <span class="verticalLine" />
              </v-col>

              <v-col cols="4">
                {{ this.getLang("v4_status", "REJECTED") }}
              </v-col>

              <v-col cols="4">
                {{ this.received_time }}
              </v-col>
            </v-row>
            <!-- RECEIVED -->
            <v-row align="center" justify="center">
              <v-col cols="4" class="d-flex">
                <v-btn
                  :color="this.get_backgroundcolor(this.received)"
                  dark
                  x-small
                  fab
                  elevation="0"
                >
                  <span class="numberText">2</span>
                </v-btn>
                <span class="verticalLine" />
              </v-col>

              <v-col cols="4">
                {{ this.getLang("v4_status", "RECEIVED") }}
              </v-col>

              <v-col cols="4">
                {{ this.received_time }}
              </v-col>
            </v-row>
            <!-- READ -->
            <v-row align="center" justify="center">
              <v-col cols="4" class="d-flex">
                <v-btn
                  :color="this.get_backgroundcolor(this.read)"
                  dark
                  x-small
                  fab
                  elevation="0"
                >
                  <span class="numberText">3</span>
                </v-btn>
                <span class="verticalLine" />
              </v-col>

              <v-col cols="4">
                {{ this.getLang("v4_status", "READ") }}
              </v-col>

              <v-col cols="4">
                {{ this.read_time }}
              </v-col>
            </v-row>
            <!-- ANSWERED -->
            <v-row align="center" justify="center">
              <v-col cols="4" class="d-flex">
                <v-btn
                  :color="this.get_backgroundcolor(this.answered)"
                  dark
                  x-small
                  fab
                  elevation="0"
                >
                  <span class="numberText">4</span>
                </v-btn>
              </v-col>

              <v-col cols="4">
                {{ this.get_answer() }}
              </v-col>

              <v-col cols="4">
                {{ this.answered_time }}
              </v-col>
            </v-row>
          </v-col>
      </v-container>
      <div v-if="show_send == false">
        <v-container>
          <v-row>
            <v-col cols="12" xl="4" lg="4" md="4" sm="12">
              <v-btn
                class="btn-send"
                dark
                @click="click_new_test"
                elevation="0"
                :color="this.get_backgroundcolor(true)"
              >
                {{getLang('page', 'send_again')}}
              </v-btn>
            </v-col>
            <v-col cols="12" xl="4" lg="4" md="4" sm="12">
              <v-btn
                class="btn-send"
                dark
                @click="click_lite"
                elevation="0"
                :color="this.get_backgroundcolor(true)"
              >
                {{getLang('page', 'more_features')}}
              </v-btn>
            </v-col>
            <v-col cols="12" xl="4" lg="4" md="4" sm="12">
              <v-btn
                class="btn-send"
                dark
                @click="click_home_page"
                elevation="0"
                :color="this.get_backgroundcolor(true)"
              >
                {{getLang('page', 'home_page')}}
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>
    <Login v-if="show_login" />
  </div>
</template>

<script>
import pageMixin from "./pageMixin.js";
import get from "lodash/get";
import Login from "./Login.vue";
import Header from "./Header.vue";
import Q from "@/questions.js";
import rest2Api from "@/api/rest2Api.js";

import { COOKIE_NAME_SESSION } from "@/constants.js";
export default {
  name: "Default",

  mixins: [pageMixin],
  props: {
    lang: String,
    tag: String,
  },
  components: {
    Login,
    Header,
  },
  data() {
    return {
      msg: "",
      show_send: true,
      phone_number: "",
      message_key: "",
      dummy: null,
      loggedin: false,
      show_login: false,
      session: false,
      send: false,
      default_msg: "",

      sent: false,
      received: false,
      rejected: false,
      read: false,
      answered: false,

      sent_time: "",
      received_time: "",
      rejected_time: "",
      read_time: "",
      answered_time: "",
      polling: null,
      poll_cnt: 0,
      poll_intervall: 0,
      show_refresh: false,
      max_poll: 20,
      title:'Infoping'
    };
  },
  watch: {
    "$store.state.infopingstore.session"() {
      var s = get(this.$store.state.infopingstore, "session", "");

      if (s != "") {
        this.loggedin = true;
        this.show_login=false;
      }
    },
    // "$store.state.infopingstore.status_msg"() {
    //console.log("watch", "status_msg");
    // },
    poll_intervall() {
      //console.log("poll_intervall", this.poll_intervall);
      if (this.poll_intervall == 0) clearInterval(this.polling);
    },
    // poll_cnt() {
    //console.log("poll_cnt", this.poll_cnt);
    //   if(this.poll_cnt>2)
    //   {

    //   clearInterval(this.polling);
    //   this.poll_intervall=0;
    //   }

    // },
  },

  computed: {},
  beforeDestroy() {
    clearInterval(this.polling);
  },
  created() {},
  methods: {
    cnt_down(){
return (this.max_poll - this.poll_cnt).toString();
    },
    click_start_refresh() {
      if (this.answered == false) {
        this.poll_cnt = 0;
        this.set_poll_intervall();
        this.pollData();
      } else this.show_refresh = false;
    },
    get_answer() {
      var answer = this.getLang("v4_status", "ANSWERED");
      if (this.answered == true) {
        answer =
          answer +
          " : " +
          get(this.$store.state.infopingstore, "answer_msg.answer", "");
      }
      return answer;
    },
    stop_polling() {
      //console.log("stop_polling");
      this.poll_intervall = 0;
      clearInterval(this.polling);
    },
    getTime(dt) {
      //2020-11-13T11:21:06.437

      var answer = "";

      var year = "";
      var month = "";
      var day = "";
      var hour = "";
      var minute = "";
      var seconde = "";

      var dt_ar = dt.split("T");

      if (dt_ar.length == 2) {
        var date = dt_ar[0];
        var time = dt_ar[1];

        var date_ar = date.split("-");
        var time_ar = time.split(":");
        if (date_ar.length == 3 && time_ar.length == 3) {
          year = date_ar[0];
          month = date_ar[1];
          day = date_ar[2];

          hour = time_ar[0];
          minute = time_ar[1];
          var s = time_ar[2];
          var s_ar = s.split(".");
          if (s_ar.length == 2) seconde = s_ar[0];
        }
      }
      if (year != "0001") answer = hour + ":" + minute + ":" + seconde;

      return answer;
    },
    is_valid_dt(dt) {
      var answer = false;
      var klockslag = this.getTime(dt);
      if (klockslag != "") answer = true;
      return answer;
    },
    setStatus(dt) {
      // var dt = get(this.$store.state.infopingstore, "status_msg.A", null);
      //console.log('setStatus',dt,this.poll_intervall)
      if (dt != null && this.poll_intervall > 0) {
        this.sent_time = this.getTime(dt.dt_sent);
        this.received_time = this.getTime(dt.dt_delivered);
        this.read_time = this.getTime(dt.dt_read);
        this.answered_time = this.getTime(dt.dt_answered);
        this.rejected_time = this.getTime(dt.dt_rejected);

        if (this.sent_time != "") {
          if (this.sent == false) {
            this.page_event_save("msg_status", "sent");
            this.sent = true;
          }
        }

        if (this.received_time != "") {
          if (this.received == false) {
            this.page_event_save("msg_status", "received");
            this.received = true;
          }
        }

        if (this.read_time != "") {
          if (this.read == false) {
            this.page_event_save("msg_status", "read");
            this.read = true;
          }
        }

        if (this.answered_time != "") {
          if (this.answered == false) {
            this.page_event_save("msg_status", "answered");

            this.answered = true;
            var obj = Q.ip_answer_msg_obj();
            obj.Q.message_key = this.message_key;
            obj.Q.phone_number = this.phone_number;
            obj.Q.lang = this.lang;
            obj = Q.clean_null_obj(obj);

            this.$store
              .dispatch("infopingstore/fetch_answer_msg", obj)
              .then((res) => {
                this.dummy = res;
              });
          }
        }

        if (this.rejected_time != "") {
          if (this.rejected == false) {
            this.page_event_save("msg_status", "rejected");
            this.rejected = true;
          }
        }
        //console.log("sent_time", this.sent_time, this.sent);
        //console.log("received_time", this.received_time, this.received);
        //console.log("read_time", this.read_time, this.read);
        //console.log("answered_time", this.answered_time, this.answered);
        //console.log("rejected_time", this.rejected_time, this.rejected);

        this.set_poll_intervall();
      }
    },
    get_poll_text() {
      if(this.answered==true)
        this.show_refresh=false;
        
      if (this.poll_intervall < 1) return "start refresh";
      else return (this.max_poll - this.poll_cnt).toString();
    },
    set_poll_intervall() {
      // REJECTED
      if (this.rejected == true) this.poll_intervall = 0;

      // ANSWERED
      if (this.answered == true) this.poll_intervall = 0;

      if (this.poll_cnt > this.max_poll) {
        this.poll_intervall = 0;
        this.show_refresh = true;
      }
      // NOT SENT
      else if (this.sent == false) this.poll_intervall = 1000;
      // NOT RECEIVED
      else if (this.received == false) this.poll_intervall = 1000;
      // NOT READ
      else if (this.read == false) this.poll_intervall = 2000;
      // NOT ANSWERED
      else if (this.answered == false) this.poll_intervall = 2000;
    },
    pollData() {
      if (this.message_key != "" && this.phone_number != "") {
        this.set_poll_intervall();
        this.polling = setInterval(() => {
          //console.log("polldata");
          // -----
          var obj = Q.ip_status_msg_obj();
          obj.Q.message_key = this.message_key;
          obj.Q.phone_number = this.get_PhoneNumber();
          obj = Q.clean_null_obj(obj);
          this.$store
            .dispatch("infopingstore/fetch_status_msg", obj)
            .then((res) => {
              //console.log(res);
              this.setStatus(get(res, "A", null));
            });
          this.poll_cnt = this.poll_cnt + 1;
          // -----
        }, this.poll_intervall);
      } else this.stop_polling();
    },
    get_btn_send_style() {
      return "color:#ffffff";
    },
    getText() {
      var answer = "";
      answer = this.getLang("ex_text", "new_way") + "\n";
      answer = answer + this.getLang("ex_text", "try_send");
      // if (answer != "") answer = answer + " - ";
      // answer = answer + this.getLang("ex_text", "yourself");
      this.default_msg = answer;
      return answer;
    },
    getLabel() {
      var answer = "";
      answer = this.getLang("settings_reg_step1", "placeholder_phone_number");
      if (answer != "") answer = answer + " - ";
      answer = answer + this.getLang("ex_text", "yourself");
      return answer;
    },
    keypressedPhone(keyEvt) {
      //console.log("", keyEvt);

      var number_cnt = this.phone_number.replace(/\D/g, "").length;
      if (number_cnt > 5) this.send = true;
      else this.send = false;

      //console.log("phone_number", this.phone_number, number_cnt);

      if (keyEvt.key == "Enter" && this.send == true) this.send_msg();
    },
    click_new_test() {
      this.page_event_save("click", "new_test");
        let lang=get(this.$store.state.infopingstore,'page.lang','en');
          let tag=get(this.$store.state.infopingstore,'page.tag','');
          let page=get(this.$store.state.infopingstore,'page.page','');
          let key=get(this.$store.state.infopingstore,'log_page_key','');
          var path = '/?lang='+lang+'&tag='+tag+'&page='+page+'&key='+key;
      window.open(path);
    },
    click_lite() {
      this.page_event_save("click", "lite");
      let lang=get(this.$store.state.infopingstore,'page.lang','en');
      let session=get(this.$store.state.infopingstore,'session','');

      const URL_LITE = "https://login.infoping.net"+'/?lang='+lang+'&s='+session;
        

      window.open(URL_LITE, "_blank");
    },
    click_home_page() {
      this.page_event_save("click", "home_page");
      window.open("https://www.infoping.net", "_blank");
    },
    send_leads_msg(body){
       var obj = Q.ip_leads_msg_obj();
       obj.Q.org_key="1330";
       obj.Q.node_id="Infoping/Leads/Page";
       obj.Q.body=body;
       obj = Q.clean_null_obj(obj);

        rest2Api.leads_msg(obj)

    },
    send_msg() {
      var session = get(this.$store.state.infopingstore, "session", "");
      if (session != "") {
        // LOG
        this.page_event_save("send");
        // LOG MSG
        if (this.msg != this.default_msg) this.page_event_save("msg", this.msg);
        else this.page_event_save("msg", "default");
        // LOG PHONE NUMBER
        if (this.phone_number == this.get_PhoneNumber())
          this.page_event_save("send_to", "self");
        else this.page_event_save("send_to", this.phone_number);

        this.show_send = false;
        var obj = Q.ip_msg_create_v4_obj();
        //console.log("msg_create_v4_obj",JSON.stringify ( obj))

        obj.Q.session = session;
        obj.Q.org_key = "0";

        obj.Q.msg_setting.days = "30";
        obj.Q.msg_setting.time = "08:00";
        obj.Q.msg_setting.reminder_after_sent = "0";
        obj.Q.msg_setting.reminder_before_expire = "0";
        obj.Q.msg_setting.permission_detail = "0";
        obj.Q.msg_setting.message_delivery_type_key = "1";
        obj.Q.msg_setting.push_response = "0";
        obj.Q.msg_setting.first_hidden = "0";
        obj.Q.msg_setting.first_count = "1";
        obj.Q.msg_setting.allow_comment = "0";

        obj.Q.receivers = new Array();
        const receiver = new Object();
        receiver.phone_number = this.phone_number;

        obj.Q.receivers = new Array();
        obj.Q.receivers.push(receiver);

        obj.Q.questions = new Array();
        const question = new Object();
        question.response_id = "3";
        question.first_hidden = "0";
        question.first_count = "1";
        question.allow_comment = "0";
        question.body = this.msg;
        question.header_text = null;
        obj.Q.questions.push(question);

        obj.Q.node_ids = null;
        obj.Q.attachments_v3 = null;

        obj = Q.clean_null_obj(obj);

        console.log("msg_create_v4_obj", JSON.stringify(obj));
        rest2Api.msg_create_v4(obj).then((res) => {
          this.message_key = get(res, "message.message_key", "");
          this.page_event_save("message_key", this.message_key);

          this.poll_intervall = 1000;
          this.pollData();

          let body='Skickat från Testa\n'
          body=body+'phone:'+this.get_PhoneNumber()+'\n';
          body=body+'city:'+get(this.$store.state.infopingstore, "ip_location.city", "")+'\n';
          body=body+'country:'+get(this.$store.state.infopingstore, "ip_location.country_name", "");+'\n';
          this.send_leads_msg(body);

        });
      }
    },
  },
  beforeMount() {
    // SEND MESSAGE

    //console.log("beforeMount", "lang", this.lang, "tag", this.tag);
    // hur sessionen hanteras
    //1. kolla session i url, den gör override på allt annat. Verifiera och spara den om giltig, annars login
    //2. kolla session i cookie, verifiera och använd den.
    //3. ingen session hittats => login

    // 1. hämta s parametern ur URL (sessionen) och kolla om det är samma som i storen annars skicka till login
    const urlparams = new URLSearchParams(window.location.search);
    var session = urlparams.get("s");

    if (!session || session == null) {
      //console.log("No valid session in url");
    }

    // 2. cookie
    if (
      (!session || session == null) &&
      this.$cookies.isKey(COOKIE_NAME_SESSION)
    ) {
      session = this.$cookies.get(COOKIE_NAME_SESSION);
    }

    // Validerar session
    if (session && session != null) {
      var obj = Q.ip_verify_token_obj();
      obj.Q.session = session;
      obj = Q.clean_null_obj(obj);

      this.$store
        .dispatch("infopingstore/fetch_verify_token", obj)
        .then((answer) => {
          if (answer.status == "1") {
            this.has_session = true;
            this.phone_number = this.get_PhoneNumber();
            this.msg = this.getText();
            this.page_event_save("phone_number", this.phone_number);
            if (parseInt(this.tag) > 0) {
              obj = Q.ip_status_msg_obj();
              obj.Q.message_key = this.tag;
              obj.Q.phone_number = this.phone_number;
              obj = Q.clean_null_obj(obj);
              this.$store
                .dispatch("infopingstore/fetch_status_msg", obj)
                .then((res) => {
                  var sent_time = get(res, "A.dt_sent", "");
                  if (this.is_valid_dt(sent_time)) {
                    this.show_send = false;
                    this.message_key = this.tag;
                    this.poll_intervall = 1000;
                    this.pollData();
                  }
                });
            }
          }
          else
          this.show_login=true
        });
    }
          else
          this.show_login=true

    obj = Q.ip_org_node_branding_obj();
    obj.Q.org_key = "1330";
    obj.Q.node_id = "Infoping/Leads/Page";
    obj = Q.clean_null_obj(obj);
    this.$store
      .dispatch("infopingstore/fetch_org_node_branding", obj)
      .then((res) => {
       
        //console.log(res)
        // FAVICON
                      const favicon = document.getElementById("favicon");
                      favicon.href = res.logo_url;
                      this.title="Infoping"

      });

    this.session = this.has_session();
  },
};
</script>
<style scoped>
.btn-send {
  min-height: 50px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 20px;
  font-weight: normal;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;
  width: 100%;
  display: flex;
  color: red im !important;
}
.verticalLine {
  border: 0.5px solid #cdcdcd;
  display: block;
  position: relative;
  top: 35px;
  left: -16px;
  /*height: 80%;*/
}
.row-status {
  border-top: 1px solid black;
  border-left: 0px;
  border-right: 0px;
  border-bottom: 1px solid black;
  height: 64px !important;
}
</style>
