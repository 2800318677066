<template>
<div class="header-grid" :style="get_style_header">
  <div class="box a ">
    <img class="header-img" :src="get_logo_url"  height="75px" width="75px"/>
    </div>
  <div class="box b"></div>
  <div 
  :class="get_class_name" 
  :style="get_style_name">
  {{header}}
  </div>
  <div class="box d"></div>
</div>
</template>

<script>
//import get from 'lodash/get';
import pageMixin from "./pageMixin.js"; 
export default {
  name: "Header",
  mixins: [pageMixin],
  props: {
    header: String,
  },
  computed:{
    
  },
  methods: {
      
    }
};
</script>