 import get from "lodash/get";

// import axios from "axios";

import Q from "@/questions.js";
import rest2Api from "@/api/rest2Api.js";

import { COOKIE_NAME_SESSION } from "@/constants.js";

export default {
  data() {
    return {
      dummy:null
    };
  },
  computed: {
      // CSS
      
    get_style_header(){
      return 'background-color:#'+get(this.$store.state.infopingstore,
        "org_node_branding.back_color",
        '')
    },
    get_style_name(){
      return 'color:#'+get(this.$store.state.infopingstore,
        "org_node_branding.front_color",
        '')
    },
    get_class_name(){
      return 'box c font-name-'+this.$vuetify.breakpoint.name;
    },
  get_logo_url(){

 
    return get(this.$store.state.infopingstore,
      "org_node_branding.logo_url",
      '')
  },

  },
  methods: {
    async lang_chk(lang) {
      await rest2Api.lang_chk(lang).then((res) => {
        //om språket inte finns returneras en
        this.lang = res;
      });
    },

    page_event_save(s_event,s_info=null){
      var _event=this.null_if_empty(s_event);
      var info =this.null_if_empty(s_info);
      var log_page_key=get(this.$store.state.infopingstore,'log_page_key',0)
      if(log_page_key>0)
      {
          let obj = new Object();
          obj.log_page_key=log_page_key;
          obj._event=_event;
          
          obj.info=info;
          obj=Q.clean_null_obj(obj);
          rest2Api.log_page_event(obj);
      }
    },
    get_backgroundcolor(color){
      if(color==true)
      return '#'+get(this.$store.state.infopingstore,
        "org_node_branding.back_color",
        '')
        else
        return '#b2b2b2'
    },
    get_PhoneNumber(){
      
      return get(this.$store.state.infopingstore,
        "verify_token.phone_number",
        '')
    },
    getLangX(c, w) {
      var answer=c+' '+w
      var lang_items=get(this.$store.state.infopingstore,'lang_filter.items',[])
      
      if(lang_items.length>0)
      {

        var filtered = this.$store.state.infopingstore.lang_filter.items.filter(
          (x) => x.controller == c && x.what == w
        )
          if(filtered.length>0)
            answer= filtered[0].text; 
      }
      
      return answer
    },
    getLang(c,w) {
 
      try {
      
      
      var filtered = this.$store.state.infopingstore.lang_filter.items.filter((x) => x.controller==c && x.what==w );
      
      return filtered[0].text;
      }
      catch
      (err)
      {
      this.dummy=err;
      return w;
      } 
      },
    null_if_empty(str){
      if(!str || str===null || str==='')
        return null
        else
        return str.toString().trim();
    },
    empty_if_null(str){
      if(!str || str===null || str==='')
        return ''
        else
        return str.toString().trim();
    },
     has_session() {
      let answer = false;
       // SPARAR TOM STRÄNG I STORE
       this.$store.dispatch("infopingstore/fetch_session", '');

      // hur sessionen hanteras
      //1. kolla session i url, den gör override på allt annat. Verifiera och spara den om giltig, annars login
      //2. kolla session i cookie, verifiera och använd den.
      //3. ingen session hittats => login

      // 1. hämta s parametern ur URL (sessionen) och kolla om det är samma som i storen annars skicka till login
      const urlparams = new URLSearchParams(window.location.search);
      var session = urlparams.get("s");

      if (!session || session == null) {
        //console.log("No valid session in url");
      }

      // 2. cookie
      if (
        (!session || session == null) &&
        this.$cookies.isKey(COOKIE_NAME_SESSION)
      ) {
        session = this.$cookies.get(COOKIE_NAME_SESSION);
      }

      // validera session om vi hittat nån
      if (session && session != null) {
        var obj = Q.ip_verify_token_obj();
        obj.Q.session = session;
        obj = Q.clean_null_obj(obj);

        this.$store
          .dispatch("infopingstore/fetch_verify_token", obj)
          .then((response) => {
            if (response.status == "1") {
              // SPARAR SESSIONEN I COOKIE
              this.$cookies.set(COOKIE_NAME_SESSION, session);

              // SPARAR SESSIONEN I STORE
              this.$store.dispatch("infopingstore/fetch_session", session);

              answer = true;

              //console.log("has_sessionr", answer);
              return answer;
            } else {
              //console.log("No valid session in url or cookie");

              this.$cookies.remove(COOKIE_NAME_SESSION);

              //console.log("has_session", answer);
              return answer;
            }
          });
      } else {
        // 3. ingen session hittad    => inloggning
        //console.log("No session found in url or cookie");

        this.$cookies.remove(COOKIE_NAME_SESSION);

        //console.log("has_session", answer);
        return answer;
      }
    },
  },
};
