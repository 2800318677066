import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import VueCookies from 'vue-cookies';
import storeInit from './store';
import vueHeadful from 'vue-headful';

Vue.component('vue-headful', vueHeadful);

Vue.config.productionTip = false;


const store = storeInit(); 

Vue.use(VueCookies);
Vue.$cookies.config('30d'); // 30 dagars expireTime på cookies default






new Vue({
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
