<template>
  <div>
    <Header header="Prova själv att skicka" />
    <Login v-if="this.has_session()==false" />
    <div v-else>
    </div>
  </div>
</template>
<script>
import pageMixin from "./pageMixin.js"; 
import get from 'lodash/get';
import Login from "./Login.vue";
import Header from "./Header.vue";
import Q from "@/questions.js";
export default {
  name: "Default",
 
  mixins: [pageMixin],
  components: {
    Login,
    Header,
  },
  data() {
    return {
      
    }
  },

  beforeMount(){
     var obj = Q.ip_org_node_branding_obj();
      obj.Q.org_key = "2629";
      obj = Q.clean_null_obj(obj);
      this.$store.dispatch("infopingstore/fetch_org_node_branding", obj).then(res =>{

        //console.log('org_node_branding',res)
        this.back_color='#'+get(res,'back_color','3F383C');
        this.front_color='#'+get(res,'back_color','ffffff');
        
      }
        
      );
  }
}
</script>